<template>
  <USlideover v-model="isOpen" class="invite-members-slideover z-[100]" @update:modelValue="closeSlideOver">
    <div class="relative flex-1 flex flex-col w-screen max-w-md h-full bg-white dark:bg-gray-900 rounded-lg shadow-lg">
      <div class="flex items-center justify-between border-b border-gray-200 dark:border-gray-800 h-16 px-6">
        <h2 class="text-xl font-fakt-medium text-gray-900 dark:text-white">
          Invite Team Members
        </h2>
        <UButton color="gray" variant="ghost" icon="i-heroicons-x-mark" @click="closeSlideOver"
          class="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200" />
      </div>

      <div class="flex-1 overflow-y-auto p-6">
        <div class="flex gap-2 items-center mt-4" v-if="isLoading">
          <Icon name="heroicons:arrow-path" class="animate-spin h-4 w-4 text-gray-600" />
          <p class="text-center text-gray-600 font-fakt-medium">Loading...</p>
        </div>

        <template v-else>
          <div v-if="remainingSeats === 0">
            <p class="text-gray-700 font-fakt-blond dark:text-gray-300">
              You've reached the maximum of {{ maxSeats }} team members for your current plan.
            </p>

            <p class="text-gray-700 mt-2 font-fakt-blond dark:text-gray-300">
              Please
              <a target="_blank" class="underline text-wobby-purple-800" href="https://wobby.ai/contact/">contact us</a>
              to add more team members.

            </p>
          </div>

          <div v-else-if="!isUserInActiveTrial">
            <p class="text-gray-700 font-fakt-blond dark:text-gray-300 mb-6">
              Invite your colleagues to <span class="font-fakt-medium">'{{ user?.organization.name }}'</span>.
              They'll receive an email to join and access shared resources.
            </p>

            <TransitionGroup name="list" tag="div">
              <div v-for="(email, index) in emails" :key="index" class="mb-4 relative">
                <div class="flex items-center">
                  <UInput v-model="emails[index]" type="email" placeholder="colleague@example.com" class="flex-grow"
                    :class="{ 'border-red-500': error && !isValidEmail(email) }" />
                  <UButton v-if="index !== 0" variant="" icon="i-heroicons-minus-circle" @click="removeEmail(index)"
                    class="ml-2 absolute right-0 hover:text-gray-400 text-gray-600 transition-all duration-200 top-1/2 transform -translate-y-1/2"
                    title="Remove this email" />
                </div>
              </div>
            </TransitionGroup>

            <UButton @click="addEmail" color="gray" variant="soft" icon="i-heroicons-plus-circle"
              class="w-full mt-2 mb-3 transition-all duration-300 ease-in-out transform">
              Add another email
            </UButton>

            <UButton @click="inviteUserAction"
              class="w-full bg-gradient-to-r font-fakt-medium from-wobby-purple-500 to-indigo-600 text-white py-2 px-4 rounded-lg shadow-md hover:from-wobby-purple-500 hover:to-wobby-purple-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition-all duration-300 ease-in-out transform"
              :class="{ 'opacity-50 cursor-not-allowed': isInviteLoading || !hasValidEmails }"
              :loading="isInviteLoading" :disabled="isInviteLoading || !hasValidEmails">
              {{ isInviteLoading ? 'Sending Invitations...' : 'Send Invitations' }}
            </UButton>

            <UAlert v-if="feedbackMessage" :type="feedbackType" :title="feedbackTitle" :description="feedbackMessage"
              class="mt-4" />
          </div>

          <div v-else>
            <p class="text-gray-700 font-fakt-blond dark:text-gray-300">
              To invite team members, you'll need to upgrade your plan. For more information or assistance, please
              <a target="_blank" class="underline text-wobby-purple-800" href="https://wobby.ai/contact/">contact
                us</a>.
            </p>
          </div>
        </template>
      </div>
    </div>
  </USlideover>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, onUnmounted } from 'vue';
import { storeToRefs } from 'pinia';
import { TransitionGroup } from 'vue';

const isOpen = ref(false);
const emails = ref(['']);
const isLoading = ref(true);
const isInviteLoading = ref(false);
const error = ref(false);
const feedbackMessage = ref('');
const feedbackType = ref<'success' | 'error'>('success');
const feedbackTitle = ref('');

const { user, isUserInActiveTrial } = storeToRefs(useIdentityStore());
const { inviteUser, getOrganizationUsers, getOrganizationFeatureUsage } = useIdentityService();

const hasValidEmails = computed(() => emails.value.some(email => isValidEmail(email)));

const remainingSeats = ref(0);
const maxSeats = ref(0);

const calculateSeats = async () => {
  if (isUserInActiveTrial.value || !user.value) {
    remainingSeats.value = 0;
    return;
  }

  const org = user.value.organization;
  if (!org || !org.tier) {
    remainingSeats.value = 0;
    return;
  }

  const maxSeatsFeature = org.tier.features.find(feature => feature.name === 'MAX_SEATS');
  if (!maxSeatsFeature) {
    remainingSeats.value = 0;
    return;
  }

  const maxSeatCount = maxSeatsFeature.usageLimit;
  maxSeats.value = maxSeatCount;

  const userCount = (await getOrganizationUsers(org.id)).length;
  remainingSeats.value = Math.max(0, maxSeatCount - userCount);
};

const openInviteMembersSlideover = async () => {
  isOpen.value = true;
  resetForm();
  isLoading.value = true;
  await calculateSeats();
  isLoading.value = false;
};

const closeSlideOver = () => {
  if (emails.value.some(email => email.trim() !== '')) {
    if (confirm('Are you sure you want to close? Your unsaved changes will be lost.')) {
      isOpen.value = false;
      feedbackMessage.value = '';
      resetForm();
    }
  } else {
    feedbackMessage.value = '';
    isOpen.value = false;
  }
};

const resetForm = () => {
  emails.value = [''];
  error.value = false;
  feedbackType.value = 'success';
};

const addEmail = () => {
  emails.value.push('');
};

const removeEmail = (index: number) => {
  emails.value.splice(index, 1);
};

const isValidEmail = (email: string) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

const inviteUserAction = async () => {
  if (!user.value || !user.value.organization) return;

  error.value = true;
  if (!hasValidEmails.value) {
    feedbackMessage.value = 'Please enter at least one valid email address.';
    feedbackType.value = 'error';
    feedbackTitle.value = 'Invalid Input';
    return;
  }

  isInviteLoading.value = true;
  feedbackMessage.value = '';

  try {
    const validEmails = emails.value.filter(isValidEmail);
    const results = await Promise.all(
      validEmails.map(email => inviteUser(user.value!.organization.id, email))
    );

    const successCount = results.filter(result => result).length;
    const failCount = validEmails.length - successCount;

    if (successCount > 0) {
      feedbackMessage.value = `${successCount} invitation${successCount > 1 ? 's' : ''} sent successfully.`;
      feedbackType.value = 'success';
      feedbackTitle.value = 'Invitations Sent';

      if (failCount > 0) {
        feedbackMessage.value += ` ${failCount} failed. Please retry those.`;
        feedbackType.value = 'error';
        feedbackTitle.value = 'Partial Success';
      } else {
        resetForm();
      }
    } else {
      feedbackMessage.value = 'Failed to send invitations. Please try again or contact support.';
      feedbackType.value = 'error';
      feedbackTitle.value = 'Invitation Failed';
    }
  } catch (error) {
    console.error('Error sending invites:', error);
    feedbackMessage.value = 'An unexpected error occurred. Please try again or contact support.';
    feedbackType.value = 'error';
    feedbackTitle.value = 'System Error';
  } finally {
    isInviteLoading.value = false;
  }
};

onMounted(() => {
  document.addEventListener('openInviteMembers', openInviteMembersSlideover);
});

onUnmounted(() => {
  document.removeEventListener('openInviteMembers', openInviteMembersSlideover);
});
</script>

<style scoped>
.list-enter-active,
.list-leave-active {
  transition: all 0.25s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
</style>