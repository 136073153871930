<template>
  <UModal v-model="isOpen">
    <UCommandPalette ref="commandPaletteRef" :groups="groups" :autoselect="false" @update:model-value="onSelect" />
  </UModal>
</template>
  
<script lang="ts" setup>
const router = useRouter()
const identityStore = useIdentityStore();
const commandPaletteRef = ref()
const isOpen = ref(false)

const openCommandPalette = () => {
  isOpen.value = true
}

const actions = computed(() => {
  const identityStore = useIdentityStore();

  let visibleActions = [
    { id: 'home', label: 'Home', icon: 'i-heroicons-home', to: '/', shortcuts: ['G', 'H'] },
    { id: 'discovery', label: 'Discovery', icon: 'i-heroicons-viewfinder-circle', to: '/discovery/search', shortcuts: ['G', 'E'] },
    { id: 'workspaces', label: 'Workspaces', icon: 'i-heroicons-wrench-screwdriver', to: '/workspaces', shortcuts: ['G', 'W'] },
    { id: 'favorites', label: 'Favorites', icon: 'i-heroicons-star', to: '/favorites', shortcuts: ['G', 'F'] },
    { id: 'my-data', label: 'Knowledge', icon: 'i-heroicons-book-open', to: '/knowledge', shortcuts: ['G', 'D'] }
  ]

  if (identityStore.hasFeature('REPORTS')) {
    visibleActions.push({ id: 'reports', label: 'Reports', icon: 'i-heroicons-document-chart-bar', to: '/reports', shortcuts: ['G', 'R'] });
  }

  if (identityStore.hasFeature('STAT_CONVERTER')) {
    visibleActions.push({ id: 'stat-converter', label: 'StatConverter', icon: 'i-heroicons-arrow-path', to: '/stat-converter', shortcuts: ['G', 'S'] });
  }

  return visibleActions || [];
})

const groups = computed(() =>
  [{
    key: 'actions',
    commands: actions.value
  }].filter(Boolean)
)

function onSelect (option: any) {
  if (option.click) {
    option.click()
  } else if (option.to) {
    router.push(option.to)
  } else if (option.href) {
    window.open(option.href, '_blank')
  }
  isOpen.value = false
}

defineShortcuts({
  meta_k: {
    usingInput: true,
    handler: () => {
      isOpen.value = !isOpen.value
    }
  },
  'g-h': () => router.push('/'),
  'g-e': () => router.push('/discovery/search'),
  'g-w': () => router.push('/workspaces'),
  'g-f': () => router.push('/favorites'),
  'g-d': () => router.push('/knowledge'),
  'g-r': () => identityStore.hasFeature('REPORTS') && router.push('/reports'),
  'g-s': () => identityStore.hasFeature('STAT_CONVERTER') && router.push('/stat-converter')
})

onMounted(() => {
  document.addEventListener('openCommandPalette', openCommandPalette);
})

onUnmounted(() => {
  document.removeEventListener('openCommandPalette', openCommandPalette);
})
</script>

<style>

</style>