<template>
    <TransitionRoot as="template" :show="show">
        <Dialog as="div" class="relative" style="z-index: 999 !important;">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
                leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                <div class="fixed inset-0 bg-wobby-purple-100 bg-opacity-60 transition-opacity" />
            </TransitionChild>
            <div class="fixed inset-0 z-10 overflow-y-auto">
                <div class="flex min-h-full items-end justify-center p-4 sm:items-center sm:p-0">
                    <TransitionChild as="template" enter="ease-out duration-300"
                        enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
                        leave-from="opacity-100 translate-y-0 sm:scale-100"
                        leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                        <DialogPanel class="relative transform overflow-hidden w-2/5 mx-auto">
                            <div class="bg-white px-6 py-5 rounded-md">
                                <div class="relative flex flex-col justify-between space-y-2">
                                    <div class=" bg-wobby-purple-100 text-wobby-purple-700 p-4 rounded-lg">
                                        <h1 class="text-3xl font-fakt-medium">Welcome to Wobby 👋</h1>
                                        <p class="text-lg font-fakt-blond">We're excited you're here, {{
                                            identityStore.user?.firstName }}. Let's get you set up!</p>
                                    </div>

                                    <div class="p-1">
                                        <p class="text-lg font-fakt-blond text-gray-900">We can't wait to see the impactful stories you unearth using Wobby. Feel free to contact us anytime for a hand getting started.</p>

                                        <div class="mt-3">
                                            <div
                                                class="bg-wobby-white ring-1 ring-gray-500 p-2.5 rounded-md ring-opacity-10">
                                                <div class="flex items-center gap-1.5 mb-3">
                                                    <Icon class="h-5 w-5 text-wobby-black" name="heroicons:video-camera">
                                                    </Icon>
                                                    <p class="text-base font-fakt-blond text-gray-900">Watch quick
                                                        overview video.</p>
                                                </div>
                                                <iframe class="flex items-center w-full h-[250px] rounded-lg"
                                                    src="https://www.youtube.com/embed/KrPfISTh9yY"
                                                    title="Getting Started with Wobby" frameborder="0"
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                    allowfullscreen></iframe>
                                            </div>
                                        </div>
                                        <div class="mt-3">
                                            <div
                                                class="bg-wobby-white ring-1 ring-gray-500 p-2.5 rounded-md ring-opacity-10">
                                                <div class="flex items-center gap-1.5 mb-3">
                                                    <Icon class="h-5 w-5 text-wobby-black" name="heroicons:academic-cap">
                                                    </Icon>
                                                    <p class="text-base font-fakt-blond text-gray-900">Read the Guide.</p>
                                                </div>
                                                <a href="https://wobby.gitbook.io/docs/" target="_blank" class="font-fakt-blond text-gray-500 hover:underline">Click here to visit the Guide</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="flex w-full mt-2 items-center justify-end">
                                    <div class="flex gap-3">
                                        <button type="button" @click="closeModal"
                                            class="rounded-md px-3 py-2 bg-white text-sm font-fakt-blond font-semibold text-gray-900 border border-gray-300 shadow-sm focus:ring-wobby-purple-500 focus:border-wobby-purple-500 hover:bg-gray-50 focus:z-10 box-sizing:border-box" id="closeWelcome">Close</button>
                                    </div>
                                </div>
                            </div>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>
  
<script setup lang="ts">
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { computed, onMounted, ref } from 'vue'

const identityStore = useIdentityStore();

const props = defineProps<{
    show: boolean;
}>();

const router = useRouter();

// Define the emits
const emit = defineEmits(["close"]);

const closeModal = () => {
    emit('close');
};



</script>



<style scoped>
.v-enter-active {
    transition: opacity 0.8s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}</style>